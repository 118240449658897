<template>
    <div class="animated fadeIn">
        <CCard>
            <CCardHeader><font-awesome-icon icon="link"/> Document File API
                <!-- <CButton 
                    v-if="da_list_all.some(function(item) { return item.status == 'Archived'})"
                    size="sm"
                    :color="!show_archived ? 'success' : 'secondary'" 
                    class="float-right" 
                    @click="show_archived = !show_archived"
                >
                    <font-awesome-icon 
                    :icon="!show_archived ? 'eye' : 'eye-slash'"/> {{!show_archived ?  'Show' : 'Hide'}} Archived
                </CButton> -->
            </CCardHeader>
            
            <CCardBody>
                <CTabs :active-tab="my_tab" @update:activeTab="changeTab">
                    <br>  
                    <CTab>
                        <template slot="title">
                            Active File API/s <CBadge color="success">( {{ [...new Set(da_lists_active.map((item) => item.name))].length }} )</CBadge>
                        </template>
                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :items="da_lists_active"
                                    :fields="da_list_fields"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                    :items-per-page="10"
                                    border
                                    items-per-page-select
                                    sorter
                                    pagination
                                    hover
                                    :loading="this.table_loading"
                                >
                                <template slot="no-items-view" v-if="this.table_loading">
                                    <center>
                                        <CSpinner
                                            style="width:4rem;height:4rem;"
                                            color="info"
                                        />
                                    </center>
                                </template>
                                <template #method="{item}">
                                    <td>
                                        <CBadge :color="getColorStatus(item.method)">
                                            {{item.method}}
                                        </CBadge>
                                    </td>
                                </template>
                                <template #url="{item}">
                                    <td :title="item.url">                
                                        {{item.url.length > 100 ? item.url.substring(0, 100) + '...' :  item.url}}
                                    </td>
                                </template>
                                <template #action="{item, index}">
                                    <td>
                                        <router-link :to="{path: `/${$store.getters['getOrganizationName']}/drs/document-template-file-api/${item.id}`}">
                                            <CButton 

                                                color="info" 
                                                shape="pill" 
                                                size="sm"
                                            >
                                                <font-awesome-icon icon="cog"/> Manage
                                            </CButton>
                                        </router-link>
                                        
                                        <!-- <CButton 
                                            v-if="$store.getters['can']('delete-document-template-file-api')"
                                            @click="removeFileAPI(item, index)"
                                            color="danger" 
                                            shape="pill" 
                                            size="sm"
                                        >
                                            <font-awesome-icon icon="times"/> Remove
                                        </CButton> -->
                                    </td>
                                </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CTab>
                    
                    <CTab>
                        <template slot="title">
                            Inactive File API/s <CBadge color="danger">( {{ [...new Set(da_lists_inactive.map((item) => item.name))].length }} )</CBadge>
                        </template>
                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :items="da_lists_inactive"
                                    :fields="da_list_fields"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                    :items-per-page="10"
                                    border
                                    items-per-page-select
                                    sorter
                                    pagination
                                    :loading="this.table_loading"
                                >
                                    <template slot="no-items-view" v-if="this.table_loading">
                                        <center>
                                            <CSpinner
                                                style="width:4rem;height:4rem;"
                                                color="info"
                                            />
                                        </center>
                                    </template>
                                    <template #method="{item}">
                                    <td>
                                        <CBadge :color="getColorStatus(item.method)">
                                            {{item.method}}
                                        </CBadge>
                                    </td>
                                </template>
                                <template #url="{item}">
                                    <td :title="item.url">                
                                        {{item.url.length > 100 ? item.url.substring(0, 100) + '...' :  item.url}}
                                    </td>
                                </template>
                                <template #action="{item, index}">
                                    <td>
                                        <template v-if="$store.getters['can']('restore-document-template')">
                                            <CButton 
                                                size="sm"
                                                shape="pill"
                                                color="success" 
                                                :disabled="restoring ? (item.id == restoring_id ? true : false) : false"
                                                @click="restore(item)"
                                            >
    
                                                <font-awesome-icon :icon="restoring ? (item.id == restoring_id ? 'circle-notch' : 'trash-restore') : 'trash-restore'" 
                                                    :pulse="restoring ? (item.id == restoring_id ? true : false) : false"/> Restore
                                            </CButton>
                                            </template>
                                            <template v-else>
                                                <i><b>No permission to restore.</b></i>
                                            </template>                                        
                                    </td>
                                </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CTab>
                </CTabs>
            </CCardBody>
        </CCard>
    </div>
</template>
<script>
export default {
    name: 'DocumentAPILists',
    data() {
        return {
            table_loading: true,
            
            // show_archived: false,

            da_list: [],
            da_list_all: [],
            my_tab: 0,
            restoring:false,
            restoring_id: null,
        }
    },
    computed: {

        da_lists_inactive: function() {
            // console.log(this.da_list_all.filter((item) => item.deleted_at != null));
            
            return this.da_list_all.filter((item) => item.deleted_at != null);
        },

        da_lists_active: function() {
            return this.da_list_all.filter((item) => item.deleted_at == null);
        },

        da_list_fields: function () {
            let custom_fields= [
                { key: 'name', label: 'Name'},
                { key: 'method' },
                { key: 'url' },
                // { key: 'status' },
            ]
            if(this.$store.getters.can('view-document-template-file-api')) {
                custom_fields.push({ key: 'action', sorter: false})
            }
            return custom_fields;
        }
    },
    created() {
        this.getDocumentFileApi();
        this.$emit('activeTab', 0);
    },
    methods: {
        changeTab: function (item) {
            this.my_tab = item
        },  
        getDocumentFileApi: function() {
            this.$Progress.start()
            axios.get('/drs/document-template-file-api/list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.da_list_all = [];
                    for (let i = 0; i < response.data.data.length; i++) {
                        let row = {
                                id:  response.data.data[i].id, 
                                name:  response.data.data[i].name, 
                                method:  response.data.data[i].method, 
                                url:  response.data.data[i].api,
                                deleted_at:  response.data.data[i].deleted_at,
                                // status: response.data.data[i].deleted_at ? 'Archived' : 'Active', 
                        }
                        // if(row.status == 'Active') {
                            this.da_list_all.push(row)                            
                        // }
                        // this.da_list_all.push(row)
                    }
                    this.table_loading = false;
                    this.$Progress.finish()
                }
            })
        },
        restore: function (item) {
            this.restoring = true;
            this.restoring_id = item.id;
            this.$Progress.start()
            axios.post('/drs/document-template-file-api/restore/' + item.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$Progress.finish()
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${item.name} restored successfully.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.da_list_all.filter(obj => obj.id === item.id)[0].deleted_at = null;
                    this.$Progress.finish() 
                    this.restoring = false;
                }
            })
        },

    }
}
</script>
